import React from "react";

function SectionTitle({ title, backgroundColor, boxShadow, supplierTitleRef }) {

  if (title === "Supplier Manager") {
    return (
      <section id="suppliers-check" className="launch-section-title launch-section-title-color">
        <div >{title}</div>
      </section>
    );
  }
  if (title === "Product Planning Expert") {
    return (
      <section id="product-planning" className="launch-section-title launch-section-title-color">
        <div >{title} </div>
      </section>
    );
  }

  if (title === "Customer Expert") {
    return (
      <section id="custom-expert" 
      className="launch-section-title launch-section-title-color">
        <div>{title} <span style={{fontSize:"20px", marginLeft:"20px"}}>{"(under construction)"}</span></div> 
      </section>
    );
  }
  if(title==="Analytics Expert"){
    return (
      <section id="analytics-expert" className="launch-section-title launch-section-title-color">
        {title}  
      </section>
    );
  }

  return (
    <section id="analytics-expert" className="launch-section-title launch-section-title-color">
      {title}  <span style={{fontSize:"20px", marginLeft:"20px"}}>{"(under construction)"}</span>
    </section>
  );
}

export default SectionTitle;
