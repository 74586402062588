import React, { useState, useRef,useEffect } from "react";
import axios from "axios";
import Message from "./Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import GooglePalmService from "./GooglePalmService";

let pdfUrl =
  "https://dotku.github.io/fin-rider/assets/pdfs/sampleManufactures.pdf";
const MY_API_KEY = "AZS8TvnMBRj6nChKYqTRsz";

function FileUploadSection({ handleClickUpload }) {
  return (
    <div className="file-upload-section" onClick={handleClickUpload}>
      Upload File
    </div>
  );
}

function SanctionsCheck({ handleClickCheck }) {
  return (
    <div className="file-upload-section" onClick={handleClickCheck}>
      Find HTSUS Code
    </div>
  );
}


function LegalRulingDutyRate({ handleDutyCheckClick }) {
  return (
    <div className="file-upload-section" onClick={handleDutyCheckClick}>
      Get Legal Check and Duty Rate
    </div>
  );
}

const OverlayPopup = ({ message, onClose }) => {
  return (
    <div className="overlay">
      <div className="popup">
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};


function Chatbot2({ invokeChat2, setInvokeChat2, chat2UploadButton,setProductTables }) {
  const uploadRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleEscapeKey();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function handleEscapeKey(){
    handleCloseOverlay();
    setInvokeChat2(false);
  }

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  function handleClickUpload() {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }

  async function handleClickCheck() {
    if(!companyCheckInput){
      setOverlayMessage("You need to upload your products document first in 'Upload Products Doc' section.")
      setShowOverlay(true);
      return;
    }
    let prev1 = allMessages;
    prev1.push(userLoadingBubble);
    setAllMessages([...prev1]);
    setTimeout(() => {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, 200);

    let companyCheckList = await myProcessAIPrompt(companyCheckInput);
    console.log("companyCheckList in chatbot2: ",companyCheckList);
    console.log(
      "companyCheckList:",
      companyCheckList.response.candidates[0].output
    );
    companyCheckList = companyCheckList.response.candidates[0].output;
    let checkResultDom = (
      <Message
        role={"user"}
        content={{ companyCheckMarkUp: companyCheckList }}
        others={{ setCompanyNames: setCompanyNames,setProductTables:setProductTables,
                  extraProductInfo:extraProductInfo }}

      />
    );

    let prev = allMessages;
    prev.pop();
    prev.push(checkResultDom);
    setAllMessages([...prev]);
    setTimeout(() => {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, 200);
  }

  const { user, isAuthenticated } = useAuth0();

  let picture = user && user.picture;
  const bot2Prompt =
    "Hello, Now we are starting classification of goods. Please Upload your product information.";

  const styles = {
    transform: `translate(${invokeChat2 ? "0%,60%" : "130%,60%"})`,
  };


  const [companyCheckInput, setCompanyCheckInput] = useState(null);
  const [companyNames, setCompanyNames] = useState(null);
  const [legalAndDutyData, setLegalAndDutyData] = useState([]);
  const [extraProductInfo, setExtraProductInfo] = useState([]);

  const userLoadingBubble = (
    <Message role={"user"} others={{ loading: true }} />
  );

  const [allMessages, setAllMessages] = useState([
    <Message role={"bot"} content={{ text: bot2Prompt }} />,
  ]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const handleFileUpload = async (selectedFile) => {
    if (selectedFile) {
      let prev1 = allMessages;
      if (prev1.length > 1) {
        prev1.pop();
      }
      prev1.push(userLoadingBubble);
      setAllMessages([...prev1]);
      setTimeout(() => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }, 200);

      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log("selectedFile", selectedFile);
      try {
        const response = await fetch(
          "https://www.filestackapi.com/api/store/S3?key=" + MY_API_KEY,
          {
            method: "POST",
            body: formData,
            headers: { "Content-Type": "application/pdf" },
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log("Response Data:", responseData);

          const textURLResponse = await genPDF2textURL(responseData.url);
          let array = [];
          for (const block of textURLResponse.data.blocks) {
            console.log("block:--------",block);
            array = [...array, ...block.textlines];
          }
          console.log("upload product data:------------",array);

          function extractLastTwoValues(data) {
            const result = [];
          
            for (let i = 1; i < data.length; i++) {
              const parts = data[i].split(' ');
              const date = parts[parts.length - 2];
              let price = parts[parts.length - 1];
              if(!price.includes("$")){
                price = "$"+price;
              }
          
              result.push([date, price]);
            }
          
            return result;
          }

          let extraData = extractLastTwoValues(array);
          
          extraData.shift();
          extraData.unshift(["Date","Price(USD)"])
          console.log("extraData:", extraData);
          setExtraProductInfo(extraData);

          const companyListMessage = (
            <Message
              role={"user"}
              content={{ userPic: picture, companyList: array }}
      
            />
          );
          let prev = allMessages;
          prev.pop();
          prev.push(companyListMessage);
          setAllMessages([...prev]);
          setTimeout(() => {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }, 200);

          let productCheckInput = {
            source: "google",
            data: JSON.stringify(textURLResponse.data.blocks),
            prompt: `try your best, predict, gen US HTSUS heading result markdown 
            table with column:
            
              "Device Name", 
              "company name", 
              "manufacture origin", 
              "HTSUS Name"
              "HTSUS" 
            
            in capital case columns by using the items in the system. 
            HTSUS code should be full 10 digits in format like "1234.56.7890" 
            Sample of HTSUS Name would be "Mobile Phones", "Portable Automatic Data Processing Machines" and ect.`,
          };
          setCompanyCheckInput(productCheckInput);
        } else {
          // Handle errors
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  async function myProcessAIPrompt(payload) {
    const { data, prompt, source } = payload;
    let response = await GooglePalmService.generateText({
      text: `for data: ###${data}### ${prompt}`,
    });

    return {
      source,
      response,
    };
  }


  async function genPDF2textURL(url) {
    const res = await axios.post(`https://dk-api-proxy.deno.dev/parse/pdf`, {
      url,
    });

    console.log("genPDF2textURL", res);
    return res;
  }

  async function handleDutyEstimation(productDeviceNames) {
    const prompt =
      "what is the duty rate for importing this product to the US?";

    const promises = productDeviceNames.map(async (productDeviceName) => {
      const response = await GooglePalmService.generateText({
        text: `for product ${productDeviceName}, ${prompt}`,
      });
      console.log(`Response for ${productDeviceName}: `, response);
      return { productDeviceName, response };
    });

    const allResponses = await Promise.all(promises);
    return allResponses;
  }

  async function handleDutyCheckClick() {
    if(!companyNames){
      setOverlayMessage("You need to find HTSUS code first in 'Finding HTSUS Code' section.")
      setShowOverlay(true);
      return;
    }
    
    try {
    let prev = allMessages;
    prev.push(userLoadingBubble);
    setAllMessages([...prev]);
    setTimeout(() => {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, 200);
  
      let productNames = companyNames;
     //  productNames = mockNames;

      let canImportMessages = await processProductNames(productNames);
      console.log("canImportMessages: ", canImportMessages);
      
      canImportMessages = canImportMessages.map(item=>
        {
          let content = item.candidates[0].content;
          let match = ImportConditionCheckCell(content);
          return {
            match: match, content:content,
          }
        }
        )
       console.log("mapped :", ); 
       let index = 0;
       for(let item of canImportMessages){
        console.log(index, item);
        index++;
       }

      const responses = await handleDutyEstimation(productNames);

      let tempArray = [];
      for (let i = 0; i < productNames.length; i++) {
        let object = {};
        object.name = productNames[i];
        object.duty = responses[i].response.candidates[0].output;
        object.importAllowed = canImportMessages[i].match!==null? "Yes":"No";
        object.importMessage =  canImportMessages[i].content;
        tempArray.push(object);
      }

      let tempAllMessage = allMessages;
      tempAllMessage.pop();

      let legalAndDutyResult = (
        <Message
          role={"user"}
          content={{ productTableData:tempArray }}   
          others={{setProductTables:setProductTables}}
        />
      );
      tempAllMessage.push(legalAndDutyResult); 
      setAllMessages([...tempAllMessage]);
      setTimeout(() => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }, 200);
      
       
      setLegalAndDutyData([...tempArray]);
      console.log("All responses: ", responses);
      console.log("tempArray: ", tempArray);
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  
  function ImportConditionCheckCell(content) {   
    const regex = /yes/i;
    const match = regex.exec(content);    
    return match;
  }

  async function processProductNames(productNames) {

    let promiseArray = [];
    for(let item of productNames){
      promiseArray.push(canImport(item));
    }

    try {
          let result = await Promise.all(promiseArray);
           console.log("final result: ", result);
      return result;
    } catch (error) {
      // Handle errors if any of the promises are rejected
      console.error(error);
    }
   
  }
  async function canImport(productName) {
    const prompt = `can ${productName} import to the US?`;

    let response = await GooglePalmService.generateMessage({
      messages: [{ content: `${prompt}` }],
    });

    return response;
  }

  const [isHover, setIsHover] = useState(false);
  const containerRef = useRef(null);

  let ActionButton ;
  if(chat2UploadButton==="item1"){
    ActionButton = <FileUploadSection handleClickUpload={handleClickUpload} />;
  }
  else if(chat2UploadButton==="item2"){
    ActionButton =  <SanctionsCheck handleClickCheck={handleClickCheck} />;
  }
  else {
    ActionButton =  <LegalRulingDutyRate handleDutyCheckClick={handleDutyCheckClick} />;
  }

  return (
    <div className="chatbot-2-container" style={styles}>
      
       {showOverlay && (
        <OverlayPopup
          message={overlayMessage}
          onClose={handleCloseOverlay}
        />
      )}

      <button
        className="chatbot-1-close" 
        onClick={() => {
          handleCloseOverlay();
          setInvokeChat2(false);
        }}
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        <FontAwesomeIcon
          icon={faXmark}
          style={{ color: isHover ? "#2d3436" : "#bdc3c7", fontSize: "50px",}}
        />
      </button>
      <div className="chat-container" ref={containerRef}>
        {allMessages.map((item) => {
          return item;
        })}
        {ActionButton}
      </div>

      {
        <div className="upload-invisible">
          Chatbot1
          <input type="file" onChange={handleFileChange} ref={uploadRef} />
          <button onClick={handleFileUpload}>Upload</button>
          <button onClick={() => genPDF2textURL(pdfUrl)}>
            get company names
          </button>
        </div>
      }
    </div>
  );
}

export default Chatbot2;
