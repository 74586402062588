import React,{} from 'react'
import logo from './tradinlogo.png'; 
import NavItem from './NavItem';
import NavUser from "./NavUser";

function Navbar({handleClickImage,scrollToSection}) {
  
    const navItems = [
        "Home", "Suppliers Check",
        "Products Check", 
        "Custom Doc Generating",
        "Reports"
    ];


  return (
    <div className='nav-bar'>
      {/*<img className='nav-logo' onClick={handleClickImage} src={output} alt='logo'></img>*/}      
      <NavItem title={"Tradin AI"} handleClickImage={handleClickImage}/>      
         {
            navItems.map((item,index)=>{
                return <NavItem title={item} key={index} handleClickImage={handleClickImage}
                scrollToSection={scrollToSection}
                />
            })
         }
         <NavUser />
    </div>
  )
}

export default Navbar